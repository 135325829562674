<template>
    <b-modal id="announcement-create-modal" title="Announcement Create Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 class="m-0">Create Announcement</h2>
      </template>
      <validation-observer ref="announcementCreateFormValidation">
        <b-form @submit.prevent>
            <b-row>
                <b-col>
                    <validation-provider #default="{ errors }" name="Type" rules="required">
                        <b-form-group label-for="type" :state="errors.length > 0 ? false : null">
                            <template #label>
                            Content Type <span class="text-danger">*</span>
                            </template>
                            <b-row>
                                <b-col>
                                    <v-select id="type" inputId="id" label="name" v-model="selectedType"
                                    :options="types" placeholder="Announcement Type" class="v-style-chooser" :clearable="false" />
                                </b-col>
                            </b-row>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>
            <template v-if="selectedType">
              <b-row v-if="selectedType.id == 1">
                  <b-col>
                      <b-form-group>
                          <quill-editor
                          v-model="text"
                          :options="options"
                          />
                      </b-form-group>
                  </b-col>
              </b-row>
              <b-row v-if="selectedType.id == 2">
                  <b-col>
                      <b-form-group label-for="image">
                      <template #label> Image </template>
                      <b-form-file v-model="image" class="mt-0"
                          :state="Boolean(image)" :accept="acceptedFiles.join(', ')"
                          @change="uploadImage($event)" placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..." ref="image" />
                      </b-form-group>
                  </b-col>
              </b-row>
            </template>
            <template v-if="image">
                <b-row class="mb-1">
                    <b-col sm="12" md="12" lg="12" xl="12">
                        <b-img :src="imageURL" rounded fluid> </b-img>
                    </b-col>
                </b-row>
            </template>
            <b-row>
                <b-col cols="10">
                    <validation-provider #default="{ errors }" name="Variant" rules="required">
                        <b-form-group label-for="variant" :state="errors.length > 0 ? false : null">
                            <template #label>
                            Type <span class="text-danger">*</span>
                            </template>
                            <b-row>
                                <b-col>
                                    <v-select id="variant" inputId="id" label="name" v-model="selectedVariant"
                                    :options="variants" placeholder="Variant" class="v-style-chooser" :clearable="false"/>
                                </b-col>
                            </b-row>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="2" class="mt-1">
                  <div :class="variantClass">
                  </div>
                </b-col>
            </b-row>
            <b-form-group class="text-right">
                <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                    Create
                </b-button>
            </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  import { quillEditor } from "vue-quill-editor";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
      quillEditor,
    },
    mixins: [util],
    props: ["showModal"],
    data() {
      return {
        options: {
            theme: "snow",
            modules: {
            toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"], // remove formatting button
            ],
            },
        },
        text: "",
        image: null,
        imageURL: "",
        required,
        acceptedFiles: [".svg",".png",".jpg"],
        types: [
            {id:1, name:"Text"},
            {id:2, name:"Image"}
        ],
        selectedType: null,
        variants: [
            // {id:1, name:"Primary", value:"primary"},
            // {id:2, name:"Secondary", value:"secondary"},
            {id:3, name:"Success", value:"success"},
            {id:4, name:"Warning", value:"warning"},
            {id:5, name:"Danger", value:"danger"},
            {id:6, name:"Info", value:"info"},
            // {id:7, name:"Light", value:"light"},
            // {id:8, name:"Dark", value:"dark"},
        ],
        selectedVariant: null,
      };
    },
    async mounted() {
      if (this.showModal) {
        try {
          this.selectedType = this.types[0]
          this.selectedVariant = this.variants[0]
        } catch (error) {
        }
      }
    },
    methods: {
      ...mapActions({
        createAnnouncement: "appData/createAnnouncement",
      }),
      async validationForm() {
        const success = await this.$refs.announcementCreateFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          let formData = new FormData();
          let dataToInsert = {
            announcement_type: this.selectedType.id,
            variant: this.selectedVariant.value,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          if(this.text){
            dataToInsert['text'] = this.text
          }
          formData.append("data", JSON.stringify(dataToInsert));
          if (this.image)
          {
            formData.append("image", this.image)
          }
          const res = await this.createAnnouncement(formData);
          if (res.status === 201) {
            this.$swal({
              title: "Announcement created successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("announcement-create-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
        async uploadImage(event) {
            try {
                this.image = null;
                if (!event.target.files[0]) {
                return;
                }
                if(!(event.target.files[0].type.includes("image/svg")) && !(event.target.files[0].type.includes("image/png")) && !(event.target.files[0].type.includes("image/jpeg"))) {
                this.$refs.image.reset();
                // this.$swal({
                //     title: "Please upload an svg image",
                //     icon: "error",
                // });
                return;
                }
                this.image = event.target.files[0];
                this.imageURL = URL.createObjectURL(this.image)
            } catch (error) {
                this.displayError(error);
            }
        },
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
      variantClass(){
        if(this.selectedVariant){
          return 'variant-class ' + 'bg-' + this.selectedVariant.value
        }
        return ''
      }
    },
    watch: {
      selectedType(newValue, oldValue) {
        if (newValue) {
          if(newValue.id == 1){
            this.image = null
            this.imageURL = null
          }
          else if(newValue.id == 2){
            this.text = null
          }
        }
      }
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@core/scss/vue/libs/quill.scss";
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }

  .variant-class {
    height: 50px;
    width: 50px;
  }
  </style>